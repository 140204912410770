import React from 'react';
import Layout from '../../components/Layout';
import { withIntl } from '../../i18n';
import FormPage from '../../templates/FormPage';

const host = process.env.GATSBY_HOST;

const form = {
  form: {
    title: 'formLiveDemoContactUs',
    type: 'autoSend',
    link: `${host}/api/1/json/public/513644/6d85995d468d2cd7d26930393473dff049842412`,
    fields: [],
    button: {}
  }
};

class Sales extends React.PureComponent {
  render() {
    return (
      <Layout>
        <FormPage data={form}/>
      </Layout>
    );
  }
}

export default withIntl(Sales);

